export const getOverviewData = () => {
    return [
        {
            // sectionImage: 'Overview/credit-one-bank-club-logo.png',
            sectionTitle: 'Coors Light Landing',
            paragraph: 'Offering the best view in Las Vegas, this event space is home to the stunning Al Davis Memorial torch and overlooks both the city lights and the entirety of Allegiant Stadium.',
            area: '24,000',
            reception: '1,200+',
            banquet: '300'
        }
    ]
}

export const getCarouselImages = () => {
    return [
        // 'CoorsLightLanding/Photos/coors-light-landing-bg.jpg',
        'CoorsLightLanding/Photos/coors-light-landing-01.jpg',
        'CoorsLightLanding/Photos/coors-light-landing-04.jpg',
        'CoorsLightLanding/Photos/coors-light-landing-02.jpg',
        'CoorsLightLanding/Photos/coors-light-landing-03.jpg',
        'CoorsLightLanding/Photos/coors-light-landing-05.jpg',
        'CoorsLightLanding/Photos/coors-light-landing-06.jpg',
        // 'CoorsLightLanding/Photos/coors-light-landing-07.jpg',
        // 'CoorsLightLanding/Photos/coors-light-landing-08.jpg',
        // 'CoorsLightLanding/Photos/coors-light-landing-09.jpg',
        // 'CoorsLightLanding/Photos/coors-light-landing-10.jpg',
        // 'CoorsLightLanding/Photos/coors-light-landing-11.jpg',

    ]
}
export const getEventPhotos = () => {
    return [
        'CoorsLightLanding/event-photos/coors-light-landing-event-1.jpg',
        'CoorsLightLanding/event-photos/coors-light-landing-event-2.jpg',
        'CoorsLightLanding/event-photos/coors-light-landing-event-3.jpg',
        'CoorsLightLanding/event-photos/coors-light-landing-event-4.jpg',
        'CoorsLightLanding/event-photos/coors-light-landing-event-5.jpg',

    ]
}

export const getFieldViewImages = () => {
    return [
        'CoorsLightLanding/FieldView/VR_Section_201_11_12_Black.jpg',
        'CoorsLightLanding/FieldView/VR_Section_201_11_12_Down.jpg',
        'CoorsLightLanding/FieldView/VR_Section_201_11_12_Front.jpg',
        'CoorsLightLanding/FieldView/VR_Section_201_11_12_Left.jpg',
        'CoorsLightLanding/FieldView/VR_Section_201_11_12_Right.jpg',
        'CoorsLightLanding/FieldView/VR_Section_201_11_12_Up.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
		'CoorsLightLanding/Layout/layout-coors-light-landing-old.jpg',
		'CoorsLightLanding/Layout/layout-coors-light-landing2.jpg'
        // 'CoorsLightLanding/Layout/layout-coors-light-landing.jpg'
    ]
}

export const getLayoutImagesMobile = () => {
    return [
        'CoorsLightLanding/Layout/layout-coors-light-landing-mbole.png'
    ]
}
