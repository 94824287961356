export const getOverviewData = () => {
    return [
        {
            sectionImage: 'Overview/twitch.png',
            // sectionTitle: 'Twitch Lounge',
            paragraph: 'Cutting edge technological capabilities combined with an edgy elegance make this space a game changer.',
            area: '20,600',
            reception: '700',
            // banquet: '300'
        }
    ]
}

export const getCarouselImages = () => {
    return [
        'TwitchLounge/Photos/twitch-lounge-bg.jpg',
        'TwitchLounge/Photos/twitch-lounge-02.jpg',
        'TwitchLounge/Photos/twitch-lounge-03.jpg',
        'TwitchLounge/Photos/twitch-lounge-04.jpg',
        'TwitchLounge/Photos/twitch-lounge-05.jpg',
        // 'CreditOneClub/Photos/credit-one-club-01.jpg',
        // 'TwitchLounge/Photos/twitch-lounge-06.jpg'
        'TwitchLounge/Photos/twitch-lounge-06.jpg',
        'TwitchLounge/Photos/twitch-lounge-07.jpg',
        'TwitchLounge/Photos/twitch-lounge-08.jpg',
        'TwitchLounge/Photos/twitch-lounge-09.jpg',
        'TwitchLounge/Photos/twitch-lounge-10.jpg',
        'TwitchLounge/Photos/twitch-lounge-11.jpg',
        'TwitchLounge/Photos/twitch-lounge-12.jpg'
    ]
}

export const getFieldViewImages = () => {
    return [
        'TwitchLounge/FieldView/VR_Section_C134_32_13_Black.jpg',
        'TwitchLounge/FieldView/VR_Section_C134_32_13_Down.jpg',
        'TwitchLounge/FieldView/VR_Section_C134_32_13_Front.jpg',
        'TwitchLounge/FieldView/VR_Section_C134_32_13_Left.jpg',
        'TwitchLounge/FieldView/VR_Section_C134_32_13_Right.jpg',
        'TwitchLounge/FieldView/VR_Section_C134_32_13_Up.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
        'TwitchLounge/Layout/layout-twitch-lounge.jpg'
    ]
}

export const getLayoutImagesMobile = () => {
    return [
        'TwitchLounge/Layout/layout-twitch-lounge-mobile.png'
    ]
}
